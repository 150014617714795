import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminUpdateService {
  private adminUpdated = new Subject<void>();

  public adminUpdated$ = this.adminUpdated.asObservable();

  public notifyNetworkGroupsUpdate() {
    // this.adminUpdated.next();
  }
}

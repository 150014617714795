import { AdminService } from 'src/_services/admin.service';
import { DeploymentService } from 'src/_services/deployment.service';
import { UserService } from 'src/_services/user.service';
import { environment } from 'src/environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { FilesystemService } from '@citadel/common-frontend/_services/filesystem.service';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import { AnalyticsService } from '@citadel/common-frontend/_services/analytics.service';
import { VersionService } from '../_services/version.service';

let loaded = false;

@Injectable()
export class AuthenticatedResolver implements Resolve<any> {
	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		private loader: LoaderService,
		private socket: SocketService,
		private version: VersionService,
    	private user: UserService,
		private admin: AdminService,
		private deployment: DeploymentService,
		private filesystem: FilesystemService
	) { }

	resolve(route: ActivatedRouteSnapshot): Promise<any> {
		const promise = new Promise(async (resolve, reject) => {
			if (loaded) {
				this.loader.setLoading(false);
				resolve(undefined);
				return;
			}

			try {
				await this.socket.init(environment.api.url, null, environment.production).toPromise();
				await this.user.init(true).toPromise();
				await this.analytics.init(environment.analytics.google.id).toPromise();

				const completion = Promise.all([this.deployment.init().toPromise(), this.version.init().toPromise(), this.admin.init().toPromise(), this.filesystem.init(environment.api.url).toPromise()]);
				completion.then(() => {
					// hacky but necessary
					const element = document.getElementById('cdm-preload');
					if (element) {
						element.remove();
					}

					this.loader.setLoading(false);
					loaded = true;

					resolve(undefined);
				});
			} catch (e) {
				// invalidate session
        console.error('cc-trace-a7e0-9347a2c1e269', e);
				this.user.logout();
			}
		});
		return promise;
	}
}

export function markForChange() {
	loaded = false;
}

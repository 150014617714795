import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
	public notificationOptions: any = {
		timeOut: 6000,
		position: ['top', 'right'],
		preventDuplicates: 'true',
		showProgressBar: false,
	};

	constructor(private cdr: ChangeDetectorRef, public loaderService: LoaderService, public viewContainerRef: ViewContainerRef) { }

	ngAfterViewInit() {
		this.loaderService.initializeOnPageload();
    // the change det ref helps avoid the bs angular error in the web console, about a change after detection (garbage)
    this.cdr.detectChanges();
    setTimeout(() => {
      this.cdr.detectChanges();
    },0);

	}

}

import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class AdminUpdateService {
    constructor() {
        this.adminUpdated = new Subject();
        this.adminUpdated$ = this.adminUpdated.asObservable();
    }
    notifyNetworkGroupsUpdate() {
        // this.adminUpdated.next();
    }
}
AdminUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function AdminUpdateService_Factory() { return new AdminUpdateService(); }, token: AdminUpdateService, providedIn: "root" });

import { Observable } from 'rxjs';
import IDeployment from 'src/_interfaces/IDeployment';
import { Injectable } from '@angular/core';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import IUser from '../_interfaces/IUser';
import Events from '../_util/events';
import {AdminUpdateService} from '@citadel/common-frontend/_services/admin.update.service';

@Injectable()
export class AdminService {
  public users : IUser[] = [];

	constructor(private socketService: SocketService, private adminUpdate: AdminUpdateService) { }

	public init(): Observable<any> {
		return new Observable((observer) => {
			observer.next();
			observer.complete();
		});
	}

	public getDeploymentFromPIN({ pin }): Observable<IDeployment> {
		return this.socketService.call(Events.ADMIN_GET_PIN_DEPLOYMENT, null, { pin });
	}

	public getUsers(): Observable<IUser[]> {
		return this.socketService.call(Events.ADMIN_GET_USERS, (users) => {
      this.users = users;
      this.adminUpdate.notifyNetworkGroupsUpdate();
    });
	}

  public getUsersSync(): IUser[] {
    return this.users;
  }

	public changeUserPassword({ clientId, password }): Observable<any> {
		return this.socketService.call(Events.ADMIN_CHANGE_PASSWORD, null, clientId, password);
	}

	public postToFeed({ title, body }): Observable<any> {
		return this.socketService.call(Events.ADMIN_POST_TO_FEED, null, { title, body });
	}

	public removeFromFeed({ postId }): Observable<any> {
		return this.socketService.call(Events.ADMIN_REMOVE_FROM_FEED, null, { postId });
	}

	public getPosts(): Observable<any> {
		return this.socketService.call(Events.ADMIN_GET_RECENT_POSTS, null);
	}
}
